// See https://dev.to/mohamadharith/mutating-query-params-in-sveltekit-without-page-reloads-or-navigations-2i2b
export const replaceStateWithQuery = (values: Record<string, string | null>) => {
  if (typeof window === 'undefined') return;
  const url = new URL(window.location.toString());
  Object.entries(values).forEach(([k, v]) => {
    if (v) {
      url.searchParams.set(encodeURIComponent(k), encodeURIComponent(v));
    } else {
      url.searchParams.delete(k);
    }
  });
  window.history.replaceState({}, '', url);
};
